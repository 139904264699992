<template>
    <span style="color: #999999;">{{time}}</span>
</template>



<script>
    export default{
        data () {
            return {
                time : '',
            }
        },
        mounted () {
            let time = setInterval(()=>{
                this.timeDown()
            },500)
        },
        props : {
            endTime : {
                type : String
            },
			releaseTime : {
			    type : String
			},
			releaseType : {
			    type : Number
			}
        },
        methods : {
            timeDown () {
                const endTime = new Date(this.endTime)
                const nowTime = new Date();
                let leftTime = parseInt((endTime.getTime()-nowTime.getTime())/1000)
                let d = parseInt(leftTime/(24*60*60))
                let h = this.formate(parseInt(leftTime/(60*60)%24))
                let m = this.formate(parseInt(leftTime/60%60))
                let s = this.formate(parseInt(leftTime%60))
				if(this.releaseType==3){
					 this.time = '已释放'
				}else {
					if(this.releaseTime != null) {
						this.time = '已释放'
					}else {
						if(leftTime <= 0){
						    this.time = '待释放'
						    return
						}
						this.time = `${d}天${h}小时${m}分${s}秒`
					}
				}
                
            },
            formate (time) {
                if(time>=10){
                    return time
                }else{
                    return `0${time}`
                }
            }
        }
    }
</script>

<style >

</style>