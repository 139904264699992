<template>
	<div class="special_mine">
		<el-breadcrumb separator-class="el-icon-arrow-right">
  			<el-breadcrumb-item :to="{ path: '/home/index' }">概况总览</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/asset/asset' }">数字通证</el-breadcrumb-item>
  			<el-breadcrumb-item>数据中心-{{pool_id}}</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="box">
			<div class="earnings">
				<div class="top">
					<div class="content">
						<span class="title">统计栏</span>
						<div style="display: inline-block;float: right;position: absolute;right: 20px;bottom: 6px;">
							<el-button v-if="judge_withdrawal" :type="judge_mention?'danger':'primary'" class="real-name"  @click="handleMention()" :disabled="ifDisabled">{{BtnText}}</el-button>
							<el-button v-if="judge_realname" type="primary" class="real-name"  @click="goAuthentication()">请实名认证</el-button>
							<el-button v-if="judge_wallet" class="validation" @click="centerDialogVisible1 = true">钱包与手机验证</el-button>
						</div>
					</div>
				</div>
				<div class="bottom">
					<div class="earnings-details">
						<div class="daily-earnings">
							<p class="hpt">可提现数量</p>
							<p class="number">{{pageform.cash}}</p>
						</div>
						<div class="total-earnings">
							<p class="hpt">锁仓数量</p>
							<p class="number">{{pageform.locked}}</p>
						</div>
						<div class="halfyear_earnings" v-if="pageform.release_180 > 0">
							<p class="hpt">180天锁仓数量</p>
							<p class="number">{{pageform.release_180}}</p>
						</div>
						<div class="install" v-if="pageform.stage == true">
							<div class="free_install">
								<p class="hpt">
									<span>自由分期</span>
									<el-popover
									    placement="top"
									    title="备注:"
									    width="200"
									    trigger="hover"
									    content="每月16号进行释放，每次平均释放1/12，共12期">
										<i class="el-icon-question" slot="reference"></i>
									</el-popover>
								</p>
								<p class="number">{{pageform.stage_cash}}</p>
							</div>
							<div class="locked_install">
								<p class="hpt">
									<span>锁仓分期</span>
									<el-popover
									    placement="top"
									    title="备注:"
									    width="200"
									    trigger="hover"
									    content="540天锁仓，到期后每天平均释放1/60，共60期">
										<i class="el-icon-question" slot="reference"></i>
									</el-popover>
								</p>
								<p class="number">{{pageform.stage_locked}}</p>
								<div class="countdown" style="display: none;" >倒计时:{{pageform.lose_release_days}}天</div>
							</div>
							
						</div>
					</div>
					
				</div>
			</div>
			<div class="prompt">
				<div class="top">
					<div class="content">
						<span class="title">温馨提示</span>
					</div>
				</div>
				<div class="bottom">
					<p>1、批量提取时只能提取每个数据中心的整数部分;</p>
					<p>2、最低提取数量要大于1才能提取;</p>
					<p>3、强烈建议，每次提取数量最好累加到10个左右再统一提取，以减少损耗;</p>
					<p>4、请完成实名认证、手机安全验证后再进行提现操作;</p>
				</div>
			</div>
		</div>
        <div class="bottom-table">
           <el-tabs type="border-card">
			   <el-tab-pane label="每日通证明细">
			       <template>
			           <el-table :data="DaybonusData" style="width: 90%;margin:20px 50px 50px 50px">
			   	        <el-table-column prop="id" label="序号" align="center"></el-table-column>
			   	        <el-table-column prop="share_time" label="时间" align="center"></el-table-column>
			   	        <el-table-column prop="share" label="额度变化" align="center"></el-table-column>
			           </el-table>
			           <div class="block">
			               <el-pagination  
			           	@current-change="handleCurrentChange4" 
			           	layout="prev, pager, next, jumper" 
			           	:page-size="Dpage.pagesize"
			           	:current-page="Dpage.currentPage"
			           	:total="Dpage.total">
			           	</el-pagination>
			           </div>
			       </template>
			   </el-tab-pane>
                <el-tab-pane label="通证明细">
                    <template>
   				        <el-table :data="bonusData" style="width: 90%;margin:20px 50px 50px 50px">
      				        <el-table-column prop="id" label="序号" align="center"></el-table-column>
      				        <el-table-column prop="share_time" label="时间" align="center"></el-table-column>
      				        <el-table-column prop="share_type" label="类型" align="center">
      				        	<template slot-scope="scope">
      				        		<span v-if="scope.row.share_type===1">25%立即释放</span>
      				        		<span v-if="scope.row.share_type===2">75%线性释放</span>
      				        	</template>
      				        </el-table-column>
					        <el-table-column prop="share_balance" label="额度变化" align="center"></el-table-column>
    			        </el-table>
				        <div class="block">
				            <el-pagination  
				        	@current-change="handleCurrentChange1" 
				        	layout="prev, pager, next, jumper" 
				        	:page-size="page.pagesize"
				        	:current-page="page.currentPage"
				        	:total="page.total">
				        	</el-pagination>
				        </div>
 			        </template>
                </el-tab-pane>
				<el-tab-pane label="每日分红明细">
				    <template>
				        <el-table :data="DaybonusHData" style="width: 90%;margin:20px 50px 50px 50px">
					        <el-table-column prop="id" label="序号" align="center"></el-table-column>
					        <el-table-column prop="share_time" label="时间" align="center"></el-table-column>
					        <el-table-column prop="share" label="额度变化" align="center"></el-table-column>
				        </el-table>
				        <div class="block">
				            <el-pagination  
				        	@current-change="handleCurrentChange5" 
				        	layout="prev, pager, next, jumper" 
				        	:page-size="Dpages.pagesize"
				        	:current-page="Dpages.currentPage"
				        	:total="Dpages.total">
				        	</el-pagination>
				        </div>
				    </template>
				</el-tab-pane>
                <el-tab-pane label="分红明细">
                    <template>
   				        <el-table :data="bonusHData" style="width: 90%;margin:20px 50px 50px 50px">
      				        <el-table-column prop="id" label="序号" align="center"></el-table-column>
      				        <el-table-column prop="share_time" label="时间" align="center"></el-table-column>
      				        <el-table-column prop="share_type" label="类型" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.share_type===1">25%立即释放</span>
									<span v-if="scope.row.share_type===2">75%线性释放</span>
								</template>
							</el-table-column>
					        <el-table-column prop="share_balance" label="额度变化" align="center"></el-table-column>
    			        </el-table>
				        <div class="block">
				            <el-pagination  
				        	@current-change="handleCurrentChange2" 
				        	layout="prev, pager, next, jumper" 
				        	:page-size="pages.pagesize"
				        	:current-page="pages.currentPage"
				        	:total="pages.total">
				        	</el-pagination>
				        </div>
 			        </template>
                </el-tab-pane>
                <el-tab-pane label="提现申请">
                    <template>
   				        <el-table :data="withdrawalData" style="width: 90%;margin:20px 50px 50px 50px">
      				        <el-table-column type="index" label="序号" align="center"></el-table-column>
      				        <el-table-column prop="applytime" label="申请时间" align="center"></el-table-column>
      				        <el-table-column prop="content" label="明细" align="center"></el-table-column>
					        <el-table-column prop="money" label="金额" align="center"></el-table-column>
                            <el-table-column label="状态" align="center">
                            	<template slot-scope="scope">
                            	    <span style="color: #84CE61;" v-if="scope.row.status===5">审核通过</span>
                            		<span style="color: #FF8C8D;" v-if="scope.row.status===3">审核失败</span>
                            		<span style="color: #1989FA;" v-if="scope.row.status===2">审核中</span>
                            		<span style="color: #1989FA;" v-if="scope.row.status===1">待审核</span>
                            	</template>
                            </el-table-column>
                            <el-table-column prop="verifytime" label="审核时间" align="center"></el-table-column>
                            <el-table-column prop="verifyinfo" label="审核备注" align="center"></el-table-column>
    			        </el-table>
				        <div class="block">
				            <el-pagination  
				        	@current-change="handleCurrentChange3" 
				        	layout="prev, pager, next, jumper" 
				        	:page-size="pagess.pagesize"
				        	:current-page="pagess.currentPage"
				        	:total="pagess.total">
				        	</el-pagination>
				        </div>
 			        </template>
                </el-tab-pane>
				<el-tab-pane label="自由分期" v-if="pageform.stage == true">
				    <template>
				        <el-table :data="freeData" style="width: 90%;margin:20px 50px 50px 50px">
							<el-table-column prop="id" label="序号" align="center"></el-table-column>
							<el-table-column prop="snapshot_time" label="时间" align="center"></el-table-column>
							<el-table-column prop="share" label="额度变化" align="center"></el-table-column>
				        </el-table>
				        <div class="block">
				            <el-pagination  
				        	@current-change="handleCurrentChange6" 
				        	layout="prev, pager, next, jumper" 
				        	:page-size="freepage.pagesize"
				        	:current-page="freepage.currentPage"
				        	:total="freepage.total">
				        	</el-pagination>
				        </div>
				    </template>
				</el-tab-pane>
				<el-tab-pane label="180释放明细" v-if="pageform.release_180 > 0">
				    <template>
				        <el-table :data="releaseData" style="width: 90%;margin:20px 50px 50px 50px">
							<el-table-column prop="id" label="序号" align="center"></el-table-column>
							<el-table-column prop="snapshot_time" label="时间" align="center"></el-table-column>
							<el-table-column prop="release_180" label="总数" align="center"></el-table-column>
							<el-table-column prop="current_release" label="分期数" align="center"></el-table-column>
				        </el-table>
				        <div class="block">
				            <el-pagination  
				        	@current-change="handleCurrentChange7" 
				        	layout="prev, pager, next, jumper" 
				        	:page-size="releasepage.pagesize"
				        	:current-page="releasepage.currentPage"
				        	:total="releasepage.total">
				        	</el-pagination>
				        </div>
				    </template>
				</el-tab-pane>
				<el-tab-pane label="锁仓分期" v-if="pageform.stage == true">
				    <template>
				        <el-table :data="lockedData" style="width: 90%;margin:20px 50px 50px 50px">
							<el-table-column prop="id" label="序号" align="center"></el-table-column>
							<el-table-column prop="snapshot_time" label="时间" align="center"></el-table-column>
							<el-table-column prop="current_release" label="额度变化" align="center"></el-table-column>
				        </el-table>
				        <div class="block">
				            <el-pagination  
				        	@current-change="handleCurrentChange8" 
				        	layout="prev, pager, next, jumper" 
				        	:page-size="lockedpage.pagesize"
				        	:current-page="lockedpage.currentPage"
				        	:total="lockedpage.total">
				        	</el-pagination>
				        </div>
				    </template>
				</el-tab-pane>
			</el-tabs>
        </div>
		<el-form ref="form" :model="form" label-width="120px">
			<el-dialog title="申请提现" :visible.sync="centerDialogVisible" width="30%" center>
  			<div class="popup">
				  <div class="top">
					  <div class="left">
						  <p class="text">可提现总数</p>
						  <p class="price">{{pageform.cash}}</p>
					  </div>
					  <div class="right">
						  <p class="text">输入提现数量</p>
						   <el-form-item prop="nums">
								<el-input v-model="form.nums" placeholder="请输入" class="popup-input" onKeypress="return(/^[1-9]*[0-9]*$/.test(String.fromCharCode(event.keyCode)))" type="number"></el-input>
							</el-form-item>
					  </div>
				  </div>
				  <div class="bottom">
						<div style="display:flex;line-height:30px;margin: 20px 20px 0;">
						</div>
						<div style="display:flex;line-height:30px;margin: 0 20px;">
							<label style="width:57px;padding-left:18px;">提现到:</label>
							<div>{{infoform.wallet}}</div>
						</div>
				  </div>
			</div>
  			<span slot="footer" class="dialog-footer">
    			<el-button @click="centerDialogVisible = false">取 消</el-button>
    			<el-button type="primary" @click="centerDialogVisible = false,handleSingle(form.nums)" :disabled="ifDisabled">{{BtnText}}</el-button>
  			</span>
		</el-dialog>
		</el-form>
		<el-form ref="form" :model="form" label-width="120px">
			<el-dialog title="重要提示" :visible.sync="warnDialog" width="30%" center>
			<div class="popup">
				  <div class="warn_title">
					  <div class="text">请确认您的地址不是火币交易所地址</div>
					  <div class="text">{{infoform.wallet}}</div>
					  <div class="text" style="color: darkred;">火币交易所地址不可提现，禁止使用</div>
				  </div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="warnDialog = false">取 消</el-button>
				<el-button type="primary" @click="clickWarn()">确 定</el-button>
			</span>
		</el-dialog>
		</el-form>
		<el-dialog title="安全验证" :visible.sync="centerDialogVisible1" width="30%" center>
		    <el-form :model="submitForm" :rules="rules" ref="submitForm" label-width="150px" class="demo-ruleForm authentication-form">
		            <el-form-item label="钱包地址" prop="wallet" placeholder="请填写地址" style="width:80%">
		                <el-input v-model="submitForm.wallet"></el-input>
		            </el-form-item>
		            <el-form-item label="手机号码" prop="mobile" placeholder="请输入手机号码" style="width:80%">
		                <el-input v-model="submitForm.mobile"></el-input>
		            </el-form-item>
		            <el-form-item label="手机验证码" prop="code" placeholder="请输入验证码" style="width:60%;position:relative;">
		                <el-input v-model="submitForm.code"></el-input>
		                <el-button type="primary" v-if="showbtn" style="position:absolute;right:-120px;width:112px" @click="handleSend(submitForm.mobile)">获取验证码</el-button>
						<el-button type="primary" v-else style="position:absolute;right:-120px;width:112px":disabled="attcode">{{code_ts}}</el-button>
		            </el-form-item>
		        </el-form>
		    <span slot="footer" class="dialog-footer">
		        <el-button @click="centerDialogVisible1 = false">取 消</el-button>
		        <el-button type="primary" @click="centerDialogVisible1 = false,handleSafe(submitForm.mobile,submitForm.wallet,submitForm.code)">确 定</el-button>
		    </span>
		</el-dialog>
		
	</div>	
</template>

<script>
import { Property } from "@/api/index";
import { Personal } from "@/api/index";
import countdown from "@/components/countdown";
export default {
    data() {
      return {
		  warnDialog:false,
		  BtnText:'分币中，今日12点开启',
		  ifDisabled:true,
		  visible: false,
		  confirm: true,  //提交验证按钮判断
		  attcode: false,  //点击获取验证码按钮判断
		  showbtn: true, // 展示获取验证码或倒计时按钮判断
		  yzcode: '',
		  code_ts: '获取验证码', //倒计时提示文字
		  sec: 60 ,// 倒计时秒数
		centerDialogVisible: false,
		centerDialogVisible1: false,
		input: '',
		form:{
			nums:'',
		},
		submitForm: {},
		rules: {
		  wallet: [
		    { required: true, message: '请填写地址', trigger: 'blur' },
		  ],
		  mobile: [
		    { required: true, message: '请输入手机号', trigger: 'blur' },
		  ],
		  code: [
		    { required: true, message: '请输入验证码', trigger: 'blur' },
		  ],
		},
		pageform:[],
		infoform:[],
		pool_id:'',
		bonusData:[],
		DaybonusData:[],
		bonusHData:[],
		DaybonusHData:[],
		withdrawalData:[],
		releaseData:[],
		freeData:[],
		lockedData:[],
		lockedpage:{//锁仓分期
			total:0,
			pagesize:10,
			currentPage: 1,
		},
		page:{//分币 
			total:0,
			pagesize:10,
			currentPage: 1,
		},
		Dpage:{//每日分币
			total:0,
			pagesize:10,
			currentPage: 1,
		},
		pages:{//分红
			total:0,
			pagesize:10,
			currentPage: 1,
		},
		Dpages:{//每日分红
			total:0,
			pagesize:10,
			currentPage: 1,
		},
		pagess:{//提现
			total:0,
			pagesize:10,
			currentPage: 1,
		},
		freepage:{//自由分期
			total:0,
			pagesize:10,
			currentPage: 1,
		},
		releasepage:{//180锁仓
			total:0,
			pagesize:10,
			currentPage: 1,
		},
		judge_withdrawal:false,
		judge_realname:false,
		judge_wallet:false,
		judge_mention:false,
      };
    },
	components:{
	    	countdown
		},
	created() {
		let { pool_id } = this.$route.query;
		if (this.pool_id == ''){
			this.pool_id = pool_id
		}
		this.Poolsinfo();
		this.Sharebonus();
		this.SharebonusH();
		this.Poolwithdrawal();
		this.DaySharebonus();
		this.DaySharebonusH();
		this.Freeinstall();
		this.Releaseinstall();
		this.LockedList();
		this.time_range("9:00", "12:00");
		setInterval(() => {
			this.time_range("9:00", "12:00");
		}, 5000);
		},
	methods: {
		formate (day) {
		    if(day>=10){
		        return day
		    }else{
		        return `0${day}`
		    }
		},
		time_range(beginTime, endTime) {
			let date = new Date();
			let year = date.getFullYear();
			let m = date.getMonth() + 1;
			let mounth = this.formate(m);
			let d = date.getDate();
			let day = this.formate(d);
		      let strb = beginTime.split(":");
		      if (strb.length != 2) {
		        return false;
		      }
		      let stre = endTime.split(":");
		      if (stre.length != 2) {
		        return false;
		      }
		      let b = new Date();
		      let e = new Date();
		      let n = new Date();
		      b.setHours(strb[0]);
		      b.setMinutes(strb[1]);
		      e.setHours(stre[0]);
		      e.setMinutes(stre[1]);
		      if (n.getTime() - b.getTime() >= 0 && n.getTime() - e.getTime() < 0) {
		        this.ifDisabled = true;
		        this.BtnText = '分币中，今日12点开启'
		      } else {
				this.ifDisabled = false;
				this.BtnText = '提现'
		      }
		    },
		 
		
		goAuthentication() {
			this.$router.push('/personal/authentication')
		},
		//分币分页
		async handleCurrentChange1(val) {
			this.page.currentPage = val;
			this.Sharebonus(this.page.currentPage)
		},
		async handleCurrentChange2(val) {
			this.pages.currentPage = val;
			this.SharebonusH(this.pages.currentPage)
		},
		async handleCurrentChange3(val) {
			this.pagess.currentPage = val;
			this.Poolwithdrawal(this.pagess.currentPage)
		},
		async handleCurrentChange4(val) {
			this.Dpage.currentPage = val;
			this.DaySharebonus(this.Dpage.currentPage)
		},
		async handleCurrentChange5(val) {
			this.Dpages.currentPage = val;
			this.DaySharebonusH(this.Dpages.currentPage)
		},
		async handleCurrentChange6(val) {
			this.freepage.currentPage = val;
			this.Freeinstall(this.freepage.currentPage)
		},
		async handleCurrentChange7(val) {
			this.releasepage.currentPage = val;
			this.Releaseinstall(this.releasepage.currentPage)
		},
		async handleCurrentChange8(val) {
			this.lockedpage.currentPage = val;
			this.LockedList(this.lockedpage.currentPage)
		},
		async Poolsinfo() {
				let { status, data } = await Property.poolsinfo({pool_id:this.pool_id}); 
				if (status) {
					this.pageform = data.data;
					//提币关闭判断
					this.judge_mention = this.pageform.uping;
					this.Ifinfo()
				}
			},
			//分币明细
		async Sharebonus(page) {
				let { status, data } = await Property.sharebonus({pool_id:this.pool_id,cate:1,page:page}); 
				if (status) {
					this.bonusData = data.data.data;
					this.page.total = data.data.total;
				}
			},
		//每日分币明细
		async DaySharebonus(page) {
				let { status, data } = await Property.poolday({pool_id:this.pool_id,cate:1,page:page}); 
				if (status) {
					this.DaybonusData = data.data.data;
					this.Dpage.total = data.data.total;
				}
			},
			//分红明细
		async SharebonusH(page) {
				let { status, data } = await Property.sharebonus({pool_id:this.pool_id,cate:2,page:page}); 
				if (status) {
					this.bonusHData = data.data.data;
					this.pages.total = data.data.total;
				}
			},
			//每日分红明细
			async DaySharebonusH(page) {
					let { status, data } = await Property.poolday({pool_id:this.pool_id,cate:2,page:page}); 
					if (status) {
						this.DaybonusHData = data.data.data;
						this.Dpages.total = data.data.total;
					}
				},
			//提现列表
		async Poolwithdrawal(page) {
				let { status, data } = await Property.poolwithdrawal({page:page}); 
				if (status) {
					this.withdrawalData = data.data.data;
					this.pagess.total = data.data.total;
				}
			},
			//自由分期列表
			async Freeinstall(page) {
					let { status, data } = await Property.freeinstall({pool_id:this.pool_id,page:page}); 
					if (status) {
						this.freeData = data.data.data;
						this.freepage.total = data.data.total;
					}
				},
				//180天锁仓明细列表
				async Releaseinstall(page) {
						let { status, data } = await Property.release180s({pool_id:this.pool_id,page:page}); 
						if (status) {
							this.releaseData = data.data.data;
							this.releasepage.total = data.data.total;
						}
					},
			//锁仓分期列表
			async LockedList(page) {
					let { status, data } = await Property.lockedlist({pool_id:this.pool_id,page:page}); 
					if (status) {
						this.lockedData = data.data.data;
						this.lockedpage.total = data.data.total;
					}
				},
			//判断信息
		async Ifinfo() {
				let { status, data } = await Personal.info();
				if (status) {
						this.infoform = data.data;
						//提现判断
						this.judge_withdrawal = this.infoform.status==5 && this.pageform.cash > 0 && this.infoform.mobile_status == 1;
						//实名判断
						this.judge_realname = this.infoform.status != 5;
						//钱包判断
						this.judge_wallet = (this.infoform.wallet == '' ||this.infoform.wallet == null) && this.infoform.mobile_status == 0;
				}
			},
			handleMention() {
				if (this.judge_mention){
					this.$message.error('数据中心正在升级数据，请稍后再试。');
					return
				}
				this.warnDialog = true
			},
			clickWarn() {
				this.warnDialog = false
				this.centerDialogVisible = true
			},
		//单个提现
		 async handleSingle (nums) {
					let { status, data} = await Property.single({pool_id:this.pool_id,nums:nums},this.form);
					if(status) {
						if(data.code == 200){
							this.$message.success(data.data);
							this.Poolsinfo()
							this.Sharebonus()
							this.SharebonusH()
							this.Poolwithdrawal()
							this.Ifinfo()
						}else{
							this.$message.error(data.message);	
						}
					}
		},
		//发送短信
			async handleSend(mobile) {
				let { status, data } = await Personal.sendsms(this.submitForm);
				if(status) {
					if (data.code == 200) {
						var timer = setInterval(() => {
						        this.sec = this.sec-1
						        this.code_ts = this.sec + 'S后重试'
						        this.showbtn = false
								this.attcode = true
						        if (this.sec === 0) {
						            clearInterval(timer)
						            this.sec = 60
						            this.code_ts = this.sec + 'S后重试'
						            this.showbtn = true
									this.attcode = false
						        }
						    }, 1000)
							this.$message.success(data.data);
					}else {
					this.$message.error(data.message);
				}
				}
			},
			//手机安全+钱包地址
			handleSafe(wallet,mobile,code) {
				this.$refs.submitForm.validate(async (valid) => {
					if (valid) {
						let { status, data } = await Personal.safe(this.submitForm);
						if (status) {
								if(data.code==200) {
									this.$message.success(data.data);
									this.Ifinfo()
								}else {
									this.$message.error(data.message);
								}
							}
						} 
				})
		},
	},
  };	
</script>

<style lang="less">
	body {
		margin: 0;
		padding: 0;
		background-color: #F0F3FA;
	}
	.special_mine {
		.el-form-item__content {
			margin-left: 30px!important;
			display: flex;
		}
		.el-form-item__error {
			margin-left: 30px;
		}
		.el-breadcrumb{
				padding: 30px;
				.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link {
					color: #999999;
					font-weight: 500;
				}
				.el-breadcrumb__item:last-child .el-breadcrumb__inner {
					color: #1493FA;
				}
			}
			.box {
				display: flex;
				margin: 0 20px;
				width: 1650px;
				.earnings {
					width: 70%;
					height: 320px;
					margin-right: 20px;
					.top {
						height: 58px;
						background-color: #F8F8F8;
						.content {
		                    position: relative;
		                    padding: 15px 20px;
		                    
							.title {
								font-size: 20px;
								color: #333333;
								font-weight: 600;
								border-left: 3px solid #1493FA;
								padding-left: 10px;
							}
							i {
								color: #999999;
								margin: 0 15px;
							}
							.tyep {
								font-size: 18px;
								color: #333333;
							}
		
						}
					}
					.bottom {
						height: 262px;
						background-color: #ffffff;
						.earnings-details {
							display: flex;
							padding-top: 45px;
							.daily-earnings {
								width: 25%;
								height: 90px;
								border-right: 1px dashed #BBBBBB;
								.hpt {
									text-align: center;
									font-size: 18px;
									color: #333333;
									font-weight: 600;
								}
								.number {
									text-align: center;
									padding-top: 25px;
									font-size: 34px;
									color: #FF5722;
									font-weight: 600;
								}
							}
							.total-earnings {
								width: 25%;
								height: 90px;
								border-right: 1px dashed #BBBBBB;
								.hpt {
									text-align: center;
									font-size: 18px;
									color: #333333;
									font-weight: 600;
								}
								.number {
									text-align: center;
									padding-top: 25px;
									font-size: 34px;
									color: #FF5722;
									font-weight: 600;
								}
							}
							.halfyear_earnings {
								width: 25%;
								height: 90px;
								border-right: 0;
								.hpt {
									text-align: center;
									font-size: 18px;
									color: #333333;
									font-weight: 600;
								}
								.number {
									text-align: center;
									padding-top: 25px;
									font-size: 34px;
									color: #FF5722;
									font-weight: 600;
								}
							}
							.install {
								width: 50%;
								display: flex;
								height: 90px;
								.free_install {
									width: 50%;
									border-right: 1px dashed #BBBBBB;
									.hpt {
										text-align: center;
										font-size: 18px;
										color: #333333;
										font-weight: 600;
										span {
											vertical-align: middle;
										}
										.el-popover__reference-wrapper {
											position: relative;
											.el-icon-question {
												position: absolute;
												top: 50%;
												transform: translateY(-50%);
												color: #0486FE;
												vertical-align: middle;
												margin-left: 10px;
												cursor: pointer;
											}
										}
									}
									.number {
										text-align: center;
										padding-top: 25px;
										font-size: 34px;
										color: #FF5722;
										font-weight: 600;
									}
								}
								.locked_install {
									width: 50%;
									position: relative;
									.countdown {
										position: absolute;
										bottom: -35px;
										left: 50%;
										transform: translateX(-50%);
										color: #FF5722;
										font-size: 20px;
									}
									.hpt {
										text-align: center;
										font-size: 18px;
										color: #333333;
										font-weight: 600;
										span {
											vertical-align: middle;
										}
										.el-popover__reference-wrapper {
											position: relative;
											.el-icon-question {
												position: absolute;
												top: 50%;
												transform: translateY(-50%);
												color: #0486FE;
												vertical-align: middle;
												margin-left: 10px;
												cursor: pointer;
											}
										}
									}
									.number {
										text-align: center;
										padding-top: 25px;
										font-size: 34px;
										color: #FF5722;
										font-weight: 600;
									}
								}
							}
							
						}
						.el-button--primary {
							margin-left: 30px;
							margin-top: 45px;
						}
					}
				}
				.prompt {
					width: calc(30% - 20px);
					height: 320px;
					.top {
						height: 58px;
						background-color: #F8F8F8;
						.content {
							padding: 15px 20px;
							.title {
								font-size: 20px;
								color: #333333;
								font-weight: 600;
								border-left: 3px solid #1493FA;
								padding-left: 10px;
							}
						}
					}
					.bottom {
						height: 262px;
						background-color: #ffffff;
						padding: 30px;
						box-sizing: border-box;
						p {
							line-height: 30px;
							font-size: 18px;
							color: #333333;
						}
					}
				}
		    }
		    .bottom-table {
		        margin: 20px;
				width: 1650px\9;
		        .table-box {
				    margin: 20px 30px;
				    height: 598px;
		            background-color: #ffffff;
		            .el-table {
						height: 500px;
						.el-table th {
		    				background-color: #F5F7FA;
						}
					}
			.block {
				position: relative;
				.el-pagination {
					position: absolute;
					right: 20px;
					bottom: 0;
				    }
			    }
			}
			
			
			}
			.popup {
				.warn_title {
					.text {
						font-size: 18px;
						color: #333333;
						padding-top: 20px;
					}
				}
				.top {
					display: flex;
					height: 120px;
					border-bottom: 1px solid #E9E9E9;
					.left {
						width: 50%;
						text-align: center;
						border-right: 1px solid #E9E9E9;
						height: 86px;
						.text {
							font-size: 18px;
							color: #333333;
							padding-bottom: 20px;
						}
						.price {
							font-size: 30px;
							color: #0486FE;
						}
					}
					.right {
						width: 50%;
						text-align: center;
						height: 86px;
						.text {
							font-size: 18px;
							color: #333333;
							padding-bottom: 20px;
						}
						.popup-input {
							width: 200px;
						}
					}
				}	
			}
			.el-form-item__content {
				margin-left: 30px!important;
			}
		.block {
			position: relative;
			.el-pagination {
				position: absolute;
				right: 20px;
				bottom: 0px;
				}
			}
	}
	
</style>